import { Box, Heading } from '@stars-ecom/shared-atoms-ui'
import { AccountResetPassword } from '@stars-ecom/shared-organisms-ui'
import { navigate } from 'gatsby'
import { isNil } from 'lodash'
import React from 'react'
import { Helmet } from 'react-helmet'

import { resetPassword } from '../services/account'
import { siteFormatter } from '../utils/metaTitleFormatter'

const ResetPasswordPage = (props) => {
  const { location } = props

  const params = new URLSearchParams(location.search)
  const token = params.get('token')

  if (isNil(token)) {
    /*navigate('/customer/account/forgotpassword', {
      state: { errorToken: true }
    })*/
  }
  const passwordReset = async (password) => {
    try {
      await resetPassword(token, password)
      navigate('/', {
        state: {
          resetPassword: true
        }
      })
    } catch (e) {
      navigate('/customer/account/forgotpassword', {
        state: { errorToken: true }
      })
    }
  }

  return (
    <Box backgroundColor="#f6f3f1" w="100%">
      <Box maxW="1000px" ml="auto" mr="auto">
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content={`Réinitialiser votre mot de passe - ${siteFormatter(
              process.env.GATSBY_API_WEBSITE
            )}`}
          />
          <meta name="robots" content="noindex" />
          <title>{`Réinitialiser votre mot de passe - ${siteFormatter(
            process.env.GATSBY_API_WEBSITE
          )}`}</title>
        </Helmet>
        <Heading
          as="h1"
          color="#333"
          fontSize={{ base: '2em', md: '3.75em' }}
          pt={{ base: '30px', md: '20px' }}
          pb={{ base: '30px', md: '20px' }}
          pr={{ base: '10px', md: '5px' }}
          pl={{ base: '10px', md: '5px' }}>
          Réinitialiser votre mot de passe
        </Heading>
        <AccountResetPassword resetPassword={passwordReset} />
      </Box>
    </Box>
  )
}

ResetPasswordPage.pageType = 'resetPassword'

export default ResetPasswordPage
